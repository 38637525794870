<template>
  <a-spin :spinning="loading">
    <a-row>
      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="simiot-descriptions"
        >
          <a-descriptions-item label="机卡分离">
            {{ data.bind_status }}
          </a-descriptions-item>
          <a-descriptions-item label="IMEI">
            {{ data.imei }}
          </a-descriptions-item>
          <a-descriptions-item label="开关机状态">
            {{ data.power_status }}
          </a-descriptions-item>
          <a-descriptions-item label="在线状态">
            {{ data.online_status }}
          </a-descriptions-item>
          <a-descriptions-item label="IP">
            {{ data.ip }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="simiot-descriptions simiot-descriptions-max-6"
        >
          <a-descriptions-item label="是否需要实名">
            {{ data.is_need_verified }}
          </a-descriptions-item>
          <a-descriptions-item label="实名认证状态">
            {{ data.authentication_status }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="simiot-descriptions simiot-descriptions-max-6"
        >
          <a-descriptions-item label="区域限制省份">
            {{ data.region_limit_area }}
          </a-descriptions-item>
          <a-descriptions-item label="区域限制状态">
            {{ data.region_limit_status }}
          </a-descriptions-item>
          <a-descriptions-item label="当前使用省份">
            {{ data.current_use_region }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
  </a-spin>
</template>

<script>
import { findSimCardDeviceAndUser } from '@/api/sim_card'

export default {
  name: 'ShowSimCardDeviceAndUser',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      data: {}
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findSimCardDeviceAndUser(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    }
  }
}
</script>
